import { useLocation } from '@remix-run/react';
import { useEffect } from 'react';
import { useEnv } from '~/utils/env.ts';
import * as gtag from '~/utils/gtags.client.ts';
import { useNonce } from '~/utils/nonce-provider.ts';

export function GoogleAnalyticsTag() {
	const nonce = useNonce();
	const env = useEnv();

	const location = useLocation();
	useEffect(() => {
		if (env.GA_TRACKING_ID?.length) {
			gtag.pageview(location.pathname, env.GA_TRACKING_ID);
		}
	}, [location, env.GA_TRACKING_ID]);

	if (!env.GA_TRACKING_ID) {
		return;
	}

	return (
		<>
			<script
				async
				src={`https://www.googletagmanager.com/gtag/js?id=${env.GA_TRACKING_ID}`}
			/>
			<script
				async
				id="gtag-init"
				nonce={nonce}
				dangerouslySetInnerHTML={{
					__html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${env.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
				}}
			/>
		</>
	);
}
